import PropTypes from 'prop-types';
import React     from 'react';

import * as S                from './info-acme.module.scss';
import { SubActionRow }      from '../sub-action-row';
import { CancelledBox }      from '../../../common/product/cancelled-box';
import { Expiry }            from '../../../common/product/expiry';
import { Price }             from '../../../common/product/price';
import { ProductName }       from '../../../common/product/prod-name';
import { RemainingTime }     from '../../../common/product/remaining-time';
import { TagLine }           from '../../../common/product/tag-line';
import HAPPY_STAR            from '../../../../assets/imgs/art/happy-star.svg';
import MEH_STAR              from '../../../../assets/imgs/art/meh-star.svg';
import { useGlobalCtx }      from '../../../../context/ctx-hook';
import * as ProductSelectors from '../../../../context/selectors/product-selectors';
import * as UserSelectors    from '../../../../context/selectors/user-selectors';
import { isInBetween }       from '../../../../helpers/time-helpers';
import { getPriceWarning }   from '../../../../helpers/skunotices-helpers';



const InfoAcme = ({product, onClose}) => {
  const {state} = useGlobalCtx();

  const SUB           = ProductSelectors.getSubscription(state, product);
  // const HAS_PAYMENT   = UserSelectors.getUserHasCredit(state);
  const HAS_PAYMENT   = UserSelectors.getProductsPaymentMethod(product, state);
  const IS_HAPPY_STAR = SUB.active && SUB.autoRenew && HAS_PAYMENT;
  const HISTORY_LIST  = React.useMemo(() => UserSelectors.getUserSubHistory(state, 'acme'), []);
  const IS_CANCELLED  = React.useMemo(
    () =>
      !SUB.active || (!SUB.autoRenew && HISTORY_LIST?.filter(({action, createdAt}) =>
        (action === 'DISABLE_AUTORENEW' && isInBetween(SUB.effectiveAt, SUB.expireOn, createdAt))
      ).length > 0),
    [SUB.autoRenew, SUB.effectiveAt, SUB.expireOn, HISTORY_LIST]
  );
  const {
    PRICE_WARNING,
    PRICE_WARNING_MSG,
    ANN_PRICE_WARNING,
    ANN_PRICE_WARNING_MSG } = getPriceWarning(SUB.sku, SUB.expireOn);

  return (
    <article className={S.acmeCard}>
      {IS_CANCELLED && <CancelledBox className={S.cancelBox}/>}
      <div className={S.acmeInfo}>
        <div className={S.leftSide}>
          <ProductName name="Simplifi" className={S.name}/>
          <TagLine uriName={SUB.tierUriName} className={S.tag}/>
          <Price
            sku={SUB.renewalSku}
            renewalFrequency={SUB.renewalFrequency}
            className={S.priceRow}
          />
        </div>

        <div className={S.rightSide}>
          {SUB.isInTrialPeriod && (<div className={S.freeTrial}>Free trial</div>)}
          {(SUB.isInTrialPeriod || !SUB.autoRenew || !HAS_PAYMENT) && <RemainingTime className={S.remainingTime} expireOn={SUB.expireOn} />}
          <Expiry product={product} className={S.infoExpiryRow}/>
         
          {PRICE_WARNING &&
            <p className={`${S.legal} ${S.priceWrn} ${S.modalView}`}>{PRICE_WARNING_MSG}</p>
          }

          {ANN_PRICE_WARNING &&
            <p className={`${S.legal} ${S.priceWrn} ${S.modalView}`}>{ANN_PRICE_WARNING_MSG}</p>
          }
        </div>
        <img
          src={IS_HAPPY_STAR ? HAPPY_STAR : MEH_STAR}
          alt={IS_HAPPY_STAR ? 'Happy Star' : 'Sad Star'}
          className={IS_HAPPY_STAR ? S.happyStar : S.sadStar}
        />
      </div>

      <SubActionRow product={product} onClose={onClose}/>
    </article>
  );
};

InfoAcme.displayName = 'InfoAcme';
InfoAcme.propTypes   = {
  product: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export { InfoAcme };
