import { getPriceBySku } from '../context/selectors/product-selectors';

// export const initTealium = () => {
//   new Promise(resolve => (
//     (a, b, c, d) => {
//       a        = `//tags.tiqcdn.com/utag/quicken/main/${process.env.REACT_APP_TEALIUM_ENV}/utag.js`;
//       b        = document;
//       c        = 'script';
//       d        = b.createElement(c);
//       d.onload = resolve;
//       d.src    = a;
//       d.id     = 'utagId';
//       d.type   = `text/java${c}`;
//       d.async  = true;
//       a        = b.getElementsByTagName(c)[0];
//       a.parentNode.insertBefore(d, a);
//     })()
//   );
// };

// export const tealiumLink = (object) => {
//   if (window?.utag?.link) {
//     window.utag.link(object);
//   }
// };

export const gtmLink = (object) => {
  if (window?.dataLayer?.push) {
    window.dataLayer.push(object);
  }
};

export const getTealiumObj = (renewalSku, tierName, PRICE) => ({
  event:           'Cart Add',
  eventAction:     'cart add',
  eventCategory:   'ecommerce',
  eventLabel:      renewalSku,
  productEdition:  tierName,
  productPrice:    PRICE,
  productQuantity: 1,
  productSKU:      renewalSku,
  productYear:     new Date().getFullYear()
});

export const BMTealiumEvent = (state, {id, postState}) => ({
  event:           'Transaction',
  eventCategory:   'ecommerce',
  eventAction:     'transaction',
  eventLabel:      'Bill Manager',
  productQuantity: 1,
  //please include the following if at all possible. Note which variables are impossible for the Bill Manager
  orderID:         id, // orderId,
  productEdition:  postState.tierName, // productName,
  productYear:     new Date().getFullYear(), //productYear,
  productSKU:      postState.sku, // SKU,
  purchasePrice:   getPriceBySku(state, postState.sku), // price
  // productPlatform:  //platform,
});

export const careTestObj = (data) => ({
  event:           'trackEvent',
  eventCategory:   data.category,
  eventAction:     'button click',
  eventLabel:      data.label,
  qcsID:           data.userId,
});

