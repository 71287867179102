import React     from 'react';
import PropTypes from 'prop-types';

import * as S                from './sub-payment-history.module.scss';
import { Pagination }        from '../../common/pagination';
import SM_PAYPAL             from '../../../assets/imgs/brand/paypal-icon.png';
import { useGlobalCtx }      from '../../../context/ctx-hook';
import { getUserSubHistory } from '../../../context/selectors/user-selectors';
import * as Constants        from '../../../helpers/constants/constants';
import { maskEmail }         from '../../../helpers/masks';
import * as PaymentHelpers   from '../../../helpers/payment-helpers';
import { getShortDate }      from '../../../helpers/time-helpers';


function getPaymentType(method) {
  switch (method) {
    case 'braintree_ach_direct_debit':
    case 'US Bank Account':
      return 'ACH';
    case 'braintree_paypal':
    case 'Braintree (PayPal)':
      return 'PAYPAL';
    case 'free':
      return 'FREE';
    case 'Credit Card':
      return 'CC';
    default:
      return 'CC';
  }
}

// Filter the order history object to work with both magento and bigcomm orders
const filterOrderType = order => {
  let respObj = {
    id: null,
    action: null,
    createdAt: null,
    order: {
      customer_email: null,
      grand_total: null,
      payment: {
        method: '',
        cc_last4: '',
        cc_type: ''
      },
    }
  };
  respObj.id = order?.id;
  respObj.action = order?.action;
  respObj.createdAt = order?.createdAt;

  if (order?.commercePlatform == 'BIGCOMMERCE') {
    respObj.order.customer_email = order.order.billing_address.email;
    respObj.order.grand_total = order.order.total_inc_tax;
    respObj.order.payment.method = order.order.payment_method;
    respObj.order.payment.cc_last4 = '';
    respObj.order.payment.cc_type = '';
  } else {
    respObj.order.customer_email = order.order.customer_email;
    respObj.order.grand_total = order.order.grand_total;
    respObj.order.payment.method = order.order.payment.method;
    respObj.order.payment.cc_type = order?.order?.payment?.cc_type ?? '';
    respObj.order.payment.cc_last4 = order?.order?.payment?.cc_last4 ?? '';
  }
  return respObj;
};

const INTERVAL = 5;

const SubHistory = ({product}) => {
  const {state}                 = useGlobalCtx();
  const [selected, setSelected] = React.useState(0);
  const HISTORY_LIST            = getUserSubHistory(state, product);

  //Bunch of shenanigans to flex my account for both the old and new versions of order history api
  const keys = { magentoOrder: 'order' };
  const replaceKeys = (value) => {
    if (!value || typeof value !== 'object') return value;
    if (Array.isArray(value)) return value.map(replaceKeys);
    return Object.fromEntries(Object
      .entries(value)
      .map(([k, v]) => [keys[k] || k, replaceKeys(v)])
    );
  };
  let FILTERED_HISTORY_LIST = [];
  HISTORY_LIST.forEach(historyItem => {
    FILTERED_HISTORY_LIST.push(replaceKeys(historyItem));
  });
  //End shenanigans
  
  const ORDER_LIST = React.useMemo(
    () => FILTERED_HISTORY_LIST?.filter(item => item.order),
    [FILTERED_HISTORY_LIST]
  );

  const LIST_LENGTH = ORDER_LIST?.length ?? 0;

  if (LIST_LENGTH === 0) {
    return <div className={S.noHistory}>No history at this time</div>;
  }

  const START_ROW = (INTERVAL * selected);
  const END_ROW   = (INTERVAL * (1 + selected));

  return (
    <>
      <div className={S.historyWrapper}>
        {ORDER_LIST.slice(START_ROW, END_ROW).map(order => {

          // const {
          //   id,
          //   action,
          //   createdAt,
          //   order: {customer_email, grand_total, payment: {method, cc_last4, cc_type}}
          // } = order;

          const {
            id,
            action,
            createdAt,
            order: { customer_email, grand_total, payment: { method, cc_last4, cc_type } }
          } = filterOrderType(order);
          
          const PRICE = PaymentHelpers.getFormattedPrice(grand_total) ?? '0.00';
          const TYPE  = getPaymentType(method);
          let text;

          if (TYPE === 'PAYPAL') text = maskEmail(customer_email);
          else if (TYPE === 'CC') text = cc_last4 ? `****${cc_last4}` : '';
          else if (TYPE === 'ACH') text = 'Account Details';

          const CREATED_DATE = getShortDate(createdAt);
          const COL_PRICE    = `${Constants.REFUND_LOOKUP[action] ? '-' : ''}$${PRICE}`;
          const DESCRIPTION  = Constants.REFUND_LOOKUP[action] !== undefined
            ? `${Constants.REFUND_LOOKUP[action] ?? ''} ${order?.postState?.tierName}`
            : `${Constants.ACTION_TRANSLATIONS[action] ?? ''} ${order?.postState?.deferredTierUriName ? order?.postState?.deferredTierUriName : order?.postState?.tierName}`;

          if (TYPE === 'ACH') {
            return (
              <div key={id} className={S.achRow}>
                <span className={S.details}>ACH Direct Debit</span>
                <span className={S.description}>{DESCRIPTION}</span>
                <span className={S.date}>{CREATED_DATE}</span>
                <span className={S.price}>{COL_PRICE}</span>
              </div>
            );
          } else {
            return (
              <div key={id} className={S.paymentRow}>
                <div className={S.img}>
                  {TYPE === 'PAYPAL' && (
                    <img className={S.smPayPal} role="presentation" src={SM_PAYPAL} alt="PayPal Logo"/>
                  )}
                  {TYPE === 'CC' && (
                    <img
                      role="presentation"
                      className={S.card}
                      src={PaymentHelpers.getCCLogo(cc_type)}
                      alt={`${cc_type} logo`}
                    />
                  )}
                </div>
                <span className={S.details} title={TYPE === 'PAYPAL' ? text : null}>{text}</span>
                <span className={S.description}>{DESCRIPTION}</span>
                <span className={S.date}>{CREATED_DATE}</span>
                <span className={S.price}>{COL_PRICE}</span>
              </div>
            );
          }
        })}
      </div>
      {LIST_LENGTH > INTERVAL && (
        <Pagination
          selected={selected}
          total={LIST_LENGTH}
          interval={INTERVAL}
          onSelection={setSelected}
        />
      )}
    </>
  );
};

SubHistory.displayName = 'SubHistory';
SubHistory.propTypes   = {
  product: PropTypes.string.isRequired
};

export {SubHistory};
