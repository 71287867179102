import React from 'react';
import PropTypes from 'prop-types';

import * as S from './info-qem.module.scss';
import { SubActionRow } from '../sub-action-row';
import { Expiry } from '../../../common/product/expiry';
import { Price } from '../../../common/product/price';
import { ProductName } from '../../../common/product/prod-name';
import { TagLine } from '../../../common/product/tag-line';
import { RemainingTime } from '../../../common/product/remaining-time';
import { useGlobalCtx } from '../../../../context/ctx-hook';
import { isInBetween } from '../../../../helpers/time-helpers';
import { CancelledBox } from '../../../common/product/cancelled-box';
import * as ProductSelectors from '../../../../context/selectors/product-selectors';
import * as UserSelectors from '../../../../context/selectors/user-selectors';
// import { getQknPriceWarn } from '../../../../helpers/skunotices-helpers';


const InfoQem = ({ product, onClose }) => {
  const { state } = useGlobalCtx();

  const SUB   = ProductSelectors.getSubscription(state, product);
  // All SKUs use renewal SKU if exists to display the price
  const skuToProcess =  SUB.renewalSku ? SUB.renewalSku : SUB.sku; 
  const PRICE = ProductSelectors.getPriceBySku(state, skuToProcess);
  const NAME = ProductSelectors.getProductName(state, product);
  const HAS_PAYMENT = UserSelectors.getProductsPaymentMethod(product, state);
  const HISTORY_LIST = UserSelectors.getUserSubHistory(state, product);

  const IS_CANCELLED = React.useMemo(
    () =>
      !SUB.active || (!SUB.autoRenew && HISTORY_LIST?.filter(({ action, createdAt }) =>
        (action === 'DISABLE_AUTORENEW' && isInBetween(SUB.effectiveAt, SUB.expireOn, createdAt))
      ).length > 0),
    [SUB.autoRenew, SUB.effectiveAt, SUB.expireOn, HISTORY_LIST]
  );

  const HAS_EXPIRED = (HISTORY_LIST?.filter(({ action }) =>  (( action === 'EXPIRE' || action==='HARD_CANCEL'))).length > 0);

  const DISCOUNTED_RENEWAL =  SUB.isInTrialPeriod && SUB.renewalSku ? ProductSelectors.getDiscountedDataBySku(state, SUB.renewalSku) : false;

  const DISPLAY_PRICE = HAS_EXPIRED ? (DISCOUNTED_RENEWAL.DisplayFullPrice ? (DISCOUNTED_RENEWAL.DisplayFullPrice) : PRICE ) : PRICE;
  
  return (
    <article className={`${S.acmeCard}`}>
      <div className={S.acmeInfo}>
        <div className={S.leftSide}>
          <ProductName name={NAME} className={S.name} />
          <TagLine uriName={SUB.tierUriName} className={S.tag} />
          <Price
            price={DISPLAY_PRICE}
            renewalFrequency={SUB.renewalFrequency}
            className={S.priceRow}
            discountObj={ DISPLAY_PRICE === PRICE ? DISCOUNTED_RENEWAL : false}
          />
        </div>

        <div className={S.rightSide}>
          {IS_CANCELLED && <CancelledBox className={S.cancelBox} />}

          {SUB.isInTrialPeriod && (<div className={S.freeTrial}>Free trial</div>)}
          {(SUB.isInTrialPeriod || !SUB.autoRenew || !HAS_PAYMENT) && <RemainingTime className={S.remainingTime} expireOn={SUB.expireOn} />}
          <Expiry product={product} className={S.infoExpiryRow} />

        </div>
      </div>
      <SubActionRow product={product} onClose={onClose} />
    </article>
  );
};

InfoQem.displayName = 'SubProductCard';
InfoQem.propTypes = {
  product: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export { InfoQem };
