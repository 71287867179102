import { MODALS }              from '../constants/state-constants';
import * as MixPanel           from '../mixpanel';
import { monthsLeft,
  daysSinceSubStart }          from '../time-helpers';
import * as Actions            from '../../context/ctx-actions';
import { useGlobalCtx }        from '../../context/ctx-hook';
import { mergeSubscription }   from '../../context/selectors/product-selectors';
import { getUserId }           from '../../context/selectors/user-selectors';
import { callUpdateAutoRenew,
  callRemindLater, callGetHistories }            from '../../network/network-calls';
import * as ProductSelectors   from '../../context/selectors/product-selectors';
import {setCtxNestedObj}       from '../../context/ctx-actions';


export const handleRemindLater = (product, onClose) => {
  const {globalDispatch, state} = useGlobalCtx();

  return async () => {
    try {
      const { data } = await callRemindLater(product);

      globalDispatch(Actions.mergeMainStateObj({
        subscriptions: ProductSelectors.mergeSubscription(state, product, data.postState),
        alert:         {
          type:     'SUCCESS',
          messages: ['You have opted in to receive a reminder e-mail.']
        }
      }));
      onClose();

    } catch (err) {      
      MixPanel.error(err, MixPanel.MIX_PANEL_IDS.REMIND_LATER);
      globalDispatch(Actions.mergeMainStateObj({
        modal: {isDisabled: false, hasError: true}
      }));
    }
  };
};

export const useCancelAr = (product) => {
  const {globalDispatch, state} = useGlobalCtx();
  const {effectiveAt, expireOn} = ProductSelectors.getSubscription(state, product);

  const MONTHS_LEFT = monthsLeft(expireOn);
  const DAYS_SINCE_START = daysSinceSubStart(effectiveAt);
  const HAS_ACME = ['acme', 'acme-google', 'acme-apple'].some(prod => state.subscriptions[prod]);
  const IS_USA = state.country === 'us';

  const NEW_SWITCH_OFFER_ON = false;
  const SWITCH_OFFER_CONDITIONS = NEW_SWITCH_OFFER_ON 
    ? IS_USA && !HAS_ACME && ['quicken-us', 'quicken-ca'].includes(product) && DAYS_SINCE_START > 14 
    : IS_USA && !HAS_ACME && ['quicken-us', 'quicken-ca'].includes(product) && DAYS_SINCE_START > 14 && MONTHS_LEFT > 1;

  return async () => {
    try {
      globalDispatch(Actions.mergeMainStateObj({modal: {isDisabled: true, hasError: false}}));

      const {data} = await callUpdateAutoRenew({
        product,
        userId:    getUserId(state),
        autoRenew: false
      });

      // Fix to make sure history-reliant UI updates occur without needing to re-call all network calls
      const updatedHistory = await callGetHistories();
      const HISTORY_MAP = updatedHistory.data.reduce((acm, {line, ...rest}) => {
        if (!acm[line]) acm[line] = [];
        return {...acm, [line]: [...acm?.[line], rest]};
      }, {});

      MixPanel.track(MixPanel.MIX_PANEL_IDS.AUTO_RENEW);
      const SUBSCRIPTIONS = mergeSubscription(state, product, data.postState);

      if (['acme', 'acme-google', 'acme-apple'].includes(product)) {
        globalDispatch(Actions.mergeMainStateObj({
          modal: {
            type:      MODALS.SURVEY,
            surveySrc: 'SIMP_CANCEL_SURVEY',
            ctx:       {product},
            isLoading: true,
            hasError:  false,
            isDisabled: false,
          },
          subscriptions: SUBSCRIPTIONS
        }));
      } else if (SWITCH_OFFER_CONDITIONS) {
        globalDispatch(Actions.mergeMainStateObj({
          modal: {
            type:      MODALS.SWITCH_TO_SIMP,
            ctx:       {product},
            isLoading:  false,
            hasError:   false,
            isDisabled: false,
          },
          subscriptions: SUBSCRIPTIONS
        }));
      } else {
        if ( product === 'billmanager') {
          globalDispatch(setCtxNestedObj('alert', {
            type: 'SUCCESS',
            messages: ['Successfully disabled renewal to Bill Manager']
          }));

          globalDispatch(setCtxNestedObj('modal', {
            type:      MODALS.ALERT,
            ctx:       {},
            isLoading: true,
            hasError:  false
          }));
        } else if (product === 'quicken-estate-manager') {

          globalDispatch(Actions.mergeMainStateObj({
            subscriptions: SUBSCRIPTIONS,
            history: HISTORY_MAP
          }));

          globalDispatch(setCtxNestedObj('alert', {
            type: 'SUCCESS',
            messages: ['Successfully disabled renewal to Quicken LifeHub'],
          }));

          globalDispatch(setCtxNestedObj('modal', {
            type: MODALS.ALERT,
            ctx: {},
            isLoading: true,
            hasError: false
          }));
        } else if (product === 'quicken-online-backup'){

          globalDispatch(Actions.mergeMainStateObj({
            subscriptions: SUBSCRIPTIONS,
            history: HISTORY_MAP
          }));

          globalDispatch(setCtxNestedObj('alert', {
            type: 'SUCCESS',
            messages: ['Successfully disabled renewal to Quicken Online Backup'],
          }));

          globalDispatch(setCtxNestedObj('modal', {
            type: MODALS.ALERT,
            ctx: {},
            isLoading: true,
            hasError: false
          }));

        } else {
          globalDispatch(Actions.mergeMainStateObj({
            modal: {
              type: MODALS.SURVEY,
              surveySrc: 'NO_SWITCH_SIMP_CANCEL_SURVEY',
              ctx: { product },
              isLoading: true,
              hasError: false,
              isDisabled: false,
            },
            subscriptions: SUBSCRIPTIONS
          }));
        }
      }
    } catch (err) {
      MixPanel.error(err, MixPanel.MIX_PANEL_IDS.AUTO_RENEW);
      globalDispatch(Actions.mergeMainStateObj({
        modal: {isDisabled: false, hasError: true}
      }));
    }
  };
};
