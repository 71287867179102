import * as MixPanel         from '../mixpanel';
import { getShortDate }      from '../time-helpers';
import * as Actions          from '../../context/ctx-actions';
import { useGlobalCtx }      from '../../context/ctx-hook';
import { mergeSubscription } from '../../context/selectors/product-selectors';
import { getUserId }         from '../../context/selectors/user-selectors';
import { checkRebrandName }  from '../../helpers/pure-functions';
import * as NetworkCalls     from '../../network/network-calls';

export const useAutoRenew = (product, autoRenew = false) => {
  const {globalDispatch, state} = useGlobalCtx();

  return async () => {
    try {
      globalDispatch(Actions.setCtxField('isDisabled', true));

      const {data} = await NetworkCalls.callUpdateAutoRenew({
        product,
        userId: getUserId(state),
        autoRenew
      });

      const NAME = data.postState.productName;

      MixPanel.track(MixPanel.MIX_PANEL_IDS.AUTO_RENEW);

      globalDispatch(Actions.mergeMainStateObj({
        isDisabled: false,
        subscriptions: mergeSubscription(state, product, data.postState),
        alert: {
          type:     'SUCCESS',
          messages: autoRenew
            ? [
              'Your membership is now set up!',
              `Your ${checkRebrandName(NAME)} renewal date is ${getShortDate(data.postState.expireOn)}`
            ] : [
              `You can continue using ${checkRebrandName(NAME)} until your subscription expires.`,
              'You can turn auto-renew on by clicking \'Set to Renew Automatically\' next to the product information.'
            ]
        },
      }));
    } catch (err) {
      console.log(err);
      MixPanel.error(err, MixPanel.MIX_PANEL_IDS.AUTO_RENEW);
      globalDispatch(Actions.setCtxField('isDisabled', false));
    }
  };
};
