import React from 'react';
import PropTypes from 'prop-types';

import * as S from './tile-qem.module.scss';
import { Legal } from '../../../common/legal';
import { ProductName } from '../../../common/product/prod-name';
import { TagLine } from '../../../common/product/tag-line';
import { Expiry } from '../../../common/product/expiry';
import { Price } from '../../../common/product/price';
import { CancelledBox } from '../../../common/product/cancelled-box';
import { RemainingTime } from '../../../common/product/remaining-time';
import { setCtxNestedObj } from '../../../../context/ctx-actions';
import { useGlobalCtx } from '../../../../context/ctx-hook';
import * as ProductSelectors from '../../../../context/selectors/product-selectors';
import * as UserSelectors from '../../../../context/selectors/user-selectors';
import * as StateConstants from '../../../../helpers/constants/state-constants';
import { useAutoRenew } from '../../../../helpers/hooks/use-auto-renew';
import * as RouteHelpers from '../../../../helpers/route-helper';
import { isInBetween } from '../../../../helpers/time-helpers';
import classNames from 'classnames';

const getAccountMsg = ({ active, autoRenew }, hasPayment) => {
  if (!active) return 'Your Lifehub subscription is no longer active';
  else if (!autoRenew) return 'In order to not lose access to LifeHub, you need to resume your subscription.';
  else if (!hasPayment) return 'In order to not lose access to LifeHub, add a payment method for renewal.';
  else return '';
};

const TileQem = ({ isModal = false }) => {
  const { globalDispatch, state } = useGlobalCtx();

  const PRODUCT = 'quicken-estate-manager';
  const QEM_BUYNOW_SKU = '170510';
  const SUB = ProductSelectors.getSubscription(state, PRODUCT);
  const HAS_PAYMENT = UserSelectors.getProductsPaymentMethod(PRODUCT, state);
  const HISTORY_LIST = UserSelectors.getUserSubHistory(state, PRODUCT);
  const MESSAGE = getAccountMsg(SUB, HAS_PAYMENT);

  const { active, autoRenew, tierUriName, productLineName, renewalFrequency, expireOn, effectiveAt, isInTrialPeriod, country } = SUB;

  // All SKUs use renewal SKU if exists to display the price
  const skuToProcess =  SUB.renewalSku ? SUB.renewalSku : SUB.sku; 
  const PRICE =  ProductSelectors.getPriceBySku(state, skuToProcess);
  
  const handleAROn = useAutoRenew(PRODUCT, true);

  const IS_CANCELLED = React.useMemo(
    () =>
      !SUB.active || (!autoRenew && HISTORY_LIST?.filter(({ action, createdAt }) =>
        (action === 'DISABLE_AUTORENEW' && isInBetween(SUB.effectiveAt, expireOn, createdAt))
      ).length > 0),
    [autoRenew, effectiveAt, expireOn, HISTORY_LIST]
  );

  const DISCOUNTED_RENEWAL = isInTrialPeriod && SUB.renewalSku ? ProductSelectors.getDiscountedDataBySku(state, SUB.renewalSku) : false;
  const HAS_EXPIRED = (HISTORY_LIST?.filter(({ action }) =>  (( action === 'EXPIRE' || action==='HARD_CANCEL'))).length > 0);

  const DISPLAY_PRICE = HAS_EXPIRED ? (DISCOUNTED_RENEWAL.DisplayFullPrice ? (DISCOUNTED_RENEWAL.DisplayFullPrice) : PRICE ) : PRICE;

  // Extracted trial label logic to account for apparent design changes, may need another look
  const SHOW_TRIAL_LABEL = isInTrialPeriod && (!IS_CANCELLED || !HAS_EXPIRED);

  const manageSubscription = React.useCallback(
    async () => globalDispatch(setCtxNestedObj('modal', {
      type: StateConstants.MODALS.SUBSCRIPTION,
      ctx: { product: PRODUCT },
      isLoading: true,
      hasError: false,
      isDisabled: false
    })),
    [globalDispatch, PRODUCT]
  );

  const handleUpdateBilling = React.useCallback(
    () => globalDispatch(setCtxNestedObj('modal', {
      type: StateConstants.MODALS.UPDATE_PAYMENT,
      ctx: { product: PRODUCT, paymentOnly: true },
      isLoading: true,
      hasError: false,
      isDisabled: false
    })),
    [globalDispatch, PRODUCT]
  );

  const updatePaymentModal = React.useCallback(
    () => globalDispatch(setCtxNestedObj('modal', {
      type: StateConstants.MODALS.UPDATE_PAYMENT,
      ctx: { product: PRODUCT, paymentOnly: false },
      isLoading: true,
      hasError: false,
      isDisabled: false
    })),
    [globalDispatch, PRODUCT]
  );

  const handleBuyNow = React.useCallback(
    () => window.location.href = RouteHelpers.getEStoreCartUrlAddProduct(QEM_BUYNOW_SKU, country)
  );

  return (
    <div className={`${S.qemTile} ${SUB.tierUriName} qem`} data-testid="SUB_QEM">
      {/* <div className={`${S.simpTile} ${SUB.tierUriName}`}> */}
      {IS_CANCELLED && <CancelledBox className={S.cancelTrialBox} />}
      <ProductName name={productLineName} className={S.acmeName} />
      <TagLine uriName={tierUriName} className={S.qknTagLine} />

      {!isModal && MESSAGE && <div className={S.productAlert}>{MESSAGE}</div>}

      {(SHOW_TRIAL_LABEL) && (
        <>
          <h4 className={S.trialHeading}>Free trial</h4>
          <RemainingTime expireOn={expireOn} className={S.trialRemaining} />
        </>
      )}
      
      <Price price={DISPLAY_PRICE} 
        product={PRODUCT} 
        renewalFrequency={renewalFrequency} 
        className={ (DISCOUNTED_RENEWAL && (!HAS_EXPIRED) ? classNames(` ${S.discountedPrice} `) : S.price)} 
        discountObj={ DISPLAY_PRICE === PRICE ? DISCOUNTED_RENEWAL : false}/>

      {active && (!autoRenew || !HAS_PAYMENT) && !SHOW_TRIAL_LABEL && (
        <RemainingTime expireOn={expireOn} className={S.remaining} />
      )}

      <Expiry product={PRODUCT} className={S.productExpiry} />

      {/* Messaing is only for CA, did not do this for US price change */}
      {/* {(TIER_NOTICES?.HAS_PRICE_UPDATE && TIER_NOTICES?.WARN_WAVE === 'WAVE1' && state.country === 'ca') &&
        <p className={[`${S.qknTagLine} ${S.caPriceWarn}`]}>
          *The annual price for Quicken {tierName} will increase to {TIER_NOTICES.NEW_PRICE} on October 18 2022. You will be charged the new price at your next renewal.
        </p>
      } */}

      <span className={S.dash} />

      {!isModal && active && autoRenew && HAS_PAYMENT && (
        <button
          type="button"
          data-testid="BTN_QKN_MANAGE_SUB"
          onClick={manageSubscription}
          className={S.ctaBtn}
        >Manage Subscription</button>
      )}

      {active && autoRenew && !HAS_PAYMENT && (
        <button
          type="button"
          data-testid="BTN_QKN_BILLING"
          onClick={handleUpdateBilling}
          className={S.ctaBtn}
        >Add Billing</button>
      )}

      {active && !autoRenew && (
        <>
          <button
            type="button"
            data-testid="QKN_RESTART_SUB"
            onClick={HAS_PAYMENT ? handleAROn : updatePaymentModal}
            className={S.ctaBtn}
          >Resume Subscription</button>
          <Legal className={S.legal} />
        </>
      )}

      {!active && (
        <button
          data-testid="LINK_RENEW_QKN"
          className={S.ctaBtn}
          onClick={handleBuyNow}
        >Buy Now</button>
      )}

      {/* </div> */}
    </div>
  );
};

TileQem.displayName = 'TileQem';
TileQem.propTypes = { isModal: PropTypes.bool };
TileQem.defaultProps = { isModal: false };

export { TileQem };
