import PropTypes from 'prop-types';
import React     from 'react';

import * as S              from './survey-modal.module.scss';
import { ModalWrapper }    from '../../modal-wrapper';
import { setCtxNestedObj } from '../../../../context/ctx-actions';
import { useGlobalCtx }    from '../../../../context/ctx-hook';
import { getSurveySrc,
  getProductCtx }          from '../../../../context/selectors/user-selectors';
import { URLS }            from '../../../../helpers/constants/constants';

const SurveyModal = ({ onClose }) => {
  const { globalDispatch, state: globalState } = useGlobalCtx();
  const surveySrc = getSurveySrc(globalState);
  const product   = getProductCtx(globalState)['product'];
  const scriptSrc = URLS[surveySrc];
    
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src   = scriptSrc;
    document.getElementById('smcx-sdk').appendChild(script);
  }, []);

  const handleClose = React.useCallback(() => {
    if (surveySrc === 'SIMP_CANCEL_SURVEY') {
      globalDispatch(setCtxNestedObj('alert', {
        type: 'SUCCESS',
        messages: ['Successfully disabled renewal to Quicken Simplifi']
      }));
    } else if (
      surveySrc === 'SWITCH_SIMP_CANCEL_SURVEY' || 
      surveySrc === 'NO_SWITCH_SIMP_CANCEL_SURVEY') {
      globalDispatch(setCtxNestedObj('alert', {
        type: 'SUCCESS',
        messages: [`Successfully disabled renewal to ${product === 'billmanager' ? 'Bill Manager' : 'Quicken Classic'}`]
      }));
    }


    onClose();
  }, [globalDispatch, onClose]);

  return (
    <ModalWrapper heading="Cancel Renewal" onClose={handleClose}>
      <div className={S.modalBody}>
        <div className={S.surveyWrapper}>
          <div id="smcx-sdk"/>
        </div>
        <button
          type="button"
          onClick={handleClose}
          className={S.surveyCloseBtn}
        >Back To My Account</button>
      </div>
    </ModalWrapper>
  );
};

SurveyModal.displayName = 'SurveyModal';
SurveyModal.propTypes   = {
  onClose: PropTypes.func.isRequired,
  ctx:     PropTypes.shape({
    product: PropTypes.string.isRequired
  }),
  surveySrc: PropTypes.string
};

export { SurveyModal };
