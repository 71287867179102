import PropTypes             from 'prop-types';
import React                 from 'react';
import * as Actions          from '../../../context/ctx-actions';
import { useGlobalCtx }      from '../../../context/ctx-hook';
import * as Options          from '../../../helpers/constants/options-constants';
import * as MixPanel         from '../../../helpers/mixpanel';
import { checkForAnyErrors } from '../../../helpers/pure-functions';
import * as Validations      from '../../../helpers/validations';
import { callUpdateAddress } from '../../../network/quicken-id-calls';
import { SelectField }       from '../../common/inputs/select-field';
import { TextField }         from '../../common/inputs/text-field';
import { Message }           from '../../common/message';
import { ModalBtnRow }       from '../modal-btn-row';
import { ModalWrapper }      from '../modal-wrapper';

import * as S from './address-modal.module.scss';


let autoComplete;

function initAutoComplete(setForm, id) {
  autoComplete = new window.google.maps.places.Autocomplete(
    document.getElementById(id),
    {
      types:                 ['address'],
      componentRestrictions: {country: ['us', 'ca']},
      fields:                ['address_components', 'adr_address']
    }
  );
  autoComplete.addListener('place_changed', () => handlePlaceSelect(setForm));
}

function validateZip(country, zip) {
  return country === 'US'
    ? Validations.validateUSPostal(zip)
    : Validations.validateCAPostal(zip);
}

export const FORM_ADDRESS_VALIDATIONS = {
  fullName: Validations.validateFullName,
  line1:    Validations.validateAddressLine1,
  line2:    () => '',
  city:     Validations.validateCity,
  state:    Validations.validateState,
  country:  Validations.validateCountry
};

async function handlePlaceSelect(setForm) {
  const place = autoComplete.getPlace()?.address_components ?? [];
  if (place?.length === 0) return;

  const PLACE = place.reduce((acm, {types, ...rest}) => {
    types.forEach(field => acm[field] = rest);
    return acm;
  }, {});

  const line1   = `${PLACE.street_number.long_name} ${PLACE.route.long_name}`;
  const city    = PLACE.locality.long_name;
  const state   = PLACE.administrative_area_level_1.short_name.toUpperCase();
  const zipCode = PLACE.postal_code.short_name;
  const country = PLACE.country.short_name.toUpperCase();

  setForm(({values, errors}) => ({
    values: {line1, line2: values.line2, city, state, zipCode, country},
    errors: {
      line1:   FORM_ADDRESS_VALIDATIONS.line1(line1),
      line2:   errors.line2,
      city:    FORM_ADDRESS_VALIDATIONS.city(city),
      zipCode: validateZip(country, zipCode),
      state:   FORM_ADDRESS_VALIDATIONS.state(state),
      country: FORM_ADDRESS_VALIDATIONS.country(country),
      form:    ''
    }
  }));
}

const AddressModal = ({onClose}) => {
  const {
    globalDispatch,
    state: {
      userId,
      isDisabled,
      personalInfo: {
        firstName, lastName,
        line1, line2, city, state, zipCode, country
      }
    }
  } = useGlobalCtx();

  const [form, setForm] = React.useState({
    values: {line1, line2, city, state, zipCode, country: country || 'US'},
    errors: {line1: '', line2: '', city: '', state: '', zipCode: '', country: '', form: ''}
  });

  const handleChange = React.useCallback(({type, target: {name, value}}) => {
    if (type === 'blur') value = value.trim();
    setForm(({values, errors}) => ({
      values: {...values, [name]: value},
      errors: {...errors, [name]: FORM_ADDRESS_VALIDATIONS[name](value)}
    }));
  }, []);

  const handleZipChange = React.useCallback(({type, target: {value}}) => {
    const COUNTRY = form.values.country;
    const IS_BLUR = type === 'blur';
    const IS_USA  = COUNTRY === 'US';

    if (IS_BLUR) value = value.trim();
    value = IS_USA
      ? value.replace(/\D/g, '').substr(0, 5)
      : value.toUpperCase();

    const ERROR_MSG = validateZip(COUNTRY, value);

    setForm(({values, errors}) => ({
      values: {...values, zipCode: value},
      errors: {
        ...errors,
        zipCode: IS_BLUR
          ? ERROR_MSG
          : ERROR_MSG === '' ? '' : errors.zipCode
      }
    }));
  }, [form.values.country]);

  const handleCountrySelection = React.useCallback(({name, value}) =>
    setForm(({values, errors}) => ({
      values: {...values, [name]: value, state: '', zipCode: ''},
      errors: {
        ...errors,
        zipCode: '',
        state: '',
        [name]:  FORM_ADDRESS_VALIDATIONS[name](value)
      }
    })),
  []
  );

  const handleSelection = React.useCallback(({name, value}) =>
    setForm(({values, errors}) => ({
      values: {...values, [name]: value},
      errors: {...errors, [name]: FORM_ADDRESS_VALIDATIONS[name](value)}
    })),
  []
  );

  const clearFormError = React.useCallback(() => {
    setForm(({values, errors}) => ({
      values: {...values},
      errors: {...errors, form: ''}
    }));
  }, []);

  const handleSubmit = React.useCallback(async e => {
    e.preventDefault();
    const line1   = e.target.elements.line1.value.trim();
    const line2   = e.target.elements.line2.value.trim();
    const city    = e.target.elements.city.value.trim();
    const state   = e.target.elements.state.value.trim();
    const zipCode = e.target.elements.zipCode.value.trim();
    const country = e.target.elements.country.value.trim();

    const ERROR_OBJ = {
      line1:   FORM_ADDRESS_VALIDATIONS.line1(line1),
      line2:   FORM_ADDRESS_VALIDATIONS.line2(line2),
      city:    FORM_ADDRESS_VALIDATIONS.city(city),
      zipCode: validateZip(country, zipCode),
      state:   FORM_ADDRESS_VALIDATIONS.state(state),
      country: FORM_ADDRESS_VALIDATIONS.country(country)
    };

    setForm({
      values: {line1, line2, city, state, zipCode, country},
      errors: ERROR_OBJ
    });

    if (!checkForAnyErrors(Object.values(ERROR_OBJ))) {
      try {
        globalDispatch(Actions.setCtxField('isDisabled', true));

        await callUpdateAddress(userId, {
          fullName: `${firstName} ${lastName}`,
          line1,
          line2,
          city,
          state,
          zipCode,
          country
        });

        MixPanel.track(MixPanel.MIX_PANEL_IDS.ADDRESS);
        globalDispatch(Actions.mergeMainStateObj({
          isDisabled: false,
          personalInfo: {
            line1,
            line2,
            city,
            state,
            zipCode,
            country
          },
          alert: {
            type:     'SUCCESS',
            messages: ['Address has successfully changed']
          }
        }));

        onClose();
      } catch (err) {
        MixPanel.error(err, MixPanel.MIX_PANEL_IDS.ADDRESS);
        setForm(({values}) => ({
          values: {...values},
          errors: {...ERROR_OBJ, form: 'GENERIC_ERROR'}
        }));
        globalDispatch(Actions.setCtxField('isDisabled', false));
      }
    }
  }, [globalDispatch, userId, firstName, lastName, onClose]);

  const HAS_ERROR = React.useMemo(
    () => Object.values(form.errors).some(v => v !== ''),
    [form.errors]
  );

  React.useEffect(
    () => initAutoComplete(setForm, 'ADDRESS_FORM_LINE_1_FIELD'),
    []
  );

  return (
    <ModalWrapper heading="Change Address" onClose={onClose}>
      <div className={S.modalBody}>
        <form onSubmit={handleSubmit}>
          {form.errors.form && <Message text={form.errors.form} type="ERROR"/>}
          <div className={S.currentAddress}>
            <h3 className={S.contentLabel}>Current Address</h3>
            {!line1
              ? <p>Address not on file</p>
              : (
                <>
                  <p className={S.contentText} data-testid="ADDRESS_FORM_LINE_1">{line1}, {line2}</p>
                  <p className={S.contentText} data-testid="ADDRESS_FORM_LINE_2">{city} {state}, {zipCode}</p>
                  <p className={S.contentText}
                    data-testid="ADDRESS_FORM_LINE_3">{country === 'US' ? 'United States' : 'Canada'}</p>
                </>
              )
            }
          </div>
          <div className={S.addressForm}>
            <TextField
              label="Address"
              name="line1"
              id="ADDRESS_FORM_LINE_1_FIELD"
              placeholder="Enter address"
              error={form.errors.line1}
              value={form.values.line1}
              disabled={isDisabled}
              onChange={handleChange}
              onBlur={handleChange}
              onFocus={clearFormError}
            />

            <TextField
              label="(Optional) Secondary Address"
              name="line2"
              id="ADDRESS_FORM_LINE_2_FIELD"
              placeholder="Building, Apartment #"
              error={form.errors.line2}
              value={form.values.line2}
              disabled={isDisabled}
              onChange={handleChange}
              onBlur={handleChange}
              onFocus={clearFormError}
            />


            <TextField
              label="City"
              name="city"
              id="ADDRESS_FORM_CITY"
              placeholder="Enter your city"
              error={form.errors.city}
              value={form.values.city}
              disabled={isDisabled}
              onChange={handleChange}
              onBlur={handleChange}
              onFocus={clearFormError}
            />


            <SelectField
              id="ADDRESS_FORM_STATE"
              label="State/Province"
              name="state"
              autoComplete="address-level1"
              error={form.errors.state}
              value={form.values.state}
              disabled={isDisabled}
              onChange={handleSelection}
              optionList={form.values.country === 'CA' ? Options.CANADIAN_OPTIONS : Options.US_STATES_TERRITORY}
              onFocus={clearFormError}
            />


            <TextField
              label="ZIP Code"
              name="zipCode"
              id="ADDRESS_FORM_ZIP_CODE"
              placeholder="Enter your ZIP code"
              error={form.errors.zipCode}
              value={form.values.zipCode}
              disabled={isDisabled}
              onChange={handleZipChange}
              onBlur={handleZipChange}
              onFocus={clearFormError}
            />


            <SelectField
              id="ADDRESS_FORM_COUNTRY"
              label="Country"
              name="country"
              autoComplete="country"
              error={form.errors.country}
              value={form.values.country}
              disabled={isDisabled}
              onChange={handleCountrySelection}
              optionList={Options.COUNTRY_OPTIONS}
              onFocus={clearFormError}
            />
          </div>


          <ModalBtnRow
            form="ADDRESS_FORM"
            isDisabled={isDisabled}
            hasError={HAS_ERROR}
            onCancel={onClose}
          />
        </form>
      </div>
    </ModalWrapper>
  );
};

AddressModal.displayName = 'AddressModal';
AddressModal.propTypes   = {
  onClose: PropTypes.func.isRequired
};

export { AddressModal };
