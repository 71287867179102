import React      from 'react';
import PropTypes  from 'prop-types';
import ClassNames from 'classnames';

import * as S                from './expiry.module.scss';
import { useGlobalCtx }      from '../../../context/ctx-hook';
import * as ProductSelectors from '../../../context/selectors/product-selectors';
import { getProductsPaymentMethod } from '../../../context/selectors/user-selectors';
import { getFormattedDate }  from '../../../helpers/time-helpers';

function getExpiryText({active = false, autoRenew = false} = {}, hasPayment) {
  if (active) {
    if (autoRenew) return hasPayment ? 'Renews' : 'Expires';
    else return 'Expires';
  } else if (!active) return 'Expired';
}

function getExpiryDate(SUB) {
  if(SUB.active === false) {
    return getFormattedDate(SUB?.inactiveAt);
  } else {
    return getFormattedDate(SUB?.expireOn);
  }
}

const Expiry = ({product, className, isInline}) => {
  const {state} = useGlobalCtx();
  const SUB     = ProductSelectors.getSubscription(state, product);

  const HAS_PAYMENT = getProductsPaymentMethod(product, state);

  return (
    <div
      data-testid="SUB_EXPIRY"
      className={ClassNames({
        [S.inlineExpiry]: isInline,
        [S.expiry]:       !isInline,
        [className]:      className
      })}
    >{getExpiryText(SUB, HAS_PAYMENT)} {getExpiryDate(SUB)}</div>)
  ;
};

Expiry.displayName = 'Expiry';
Expiry.propTypes   = {
  product:   PropTypes.string.isRequired,
  className: PropTypes.string,
  isInline:  PropTypes.bool
};

Expiry.defaultProps = {
  isInline: false
};

export { Expiry };
