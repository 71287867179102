import React         from 'react';
import { SlideDown } from 'react-slidedown';
import PropTypes     from 'prop-types';
import ClassNames    from 'classnames';

import * as S              from './select-field.module.scss';
import { useOutsideClick } from '../../../helpers/hooks/use-outside-click';

function SelectField({
  id,
  label,
  error,
  name,
  value,
  onChange,
  className,
  optionList,
  ...rest
}) {
  const LIST_REF                = React.useRef(null);
  const [showList, setShowList] = React.useState(false);

  const VALUE = React.useMemo(
    () => optionList.find(({value: optVal}) => optVal === value)?.label,
    [optionList, value]
  );

  useOutsideClick(LIST_REF, () => setShowList(false));

  const handleSelection = React.useCallback((e, val) => {
    e.preventDefault();
    onChange({name: name, value: val});
    setShowList(false);
  }, [onChange, name]);

  return (
    <div className={ClassNames(S.newSelectField, className)}>
      <label data-testid={`${id}_LABEL`} htmlFor={id}>{label}</label>

      <button
        type="button"
        id={id}
        name={name}
        value={value}
        onClick={() => setShowList(true)}
        className={ClassNames(S.selectField, showList ? S.isOpen : null)}
        onFocus={() => setShowList(true)}
        data-testid={`${id}_SELECTED_BTN`}
        {...rest}
      >
        {VALUE}
      </button>
      <SlideDown closed={!showList} as="ul" ref={LIST_REF}>
        {optionList.map(({value, label, disabled}) => (
          <li key={value}>
            <button
              className={ClassNames({
                [S.selected]: value === rest.value,
                [S.disabled]: disabled
              })}
              onClick={e => !disabled ? handleSelection(e, value) : null}
              disabled={disabled ? disabled : false}
            >{label}</button>
          </li>
        ))}
      </SlideDown>
      <p data-testid={`${id}_ERROR`}>{error}</p>
    </div>
  );
}

SelectField.displayName = 'NewSelect';
SelectField.propTypes   = {
  error:     PropTypes.string,
  value:     PropTypes.string,
  className: PropTypes.string,
  tabIndex:  PropTypes.number,

  name:     PropTypes.string.isRequired,
  id:       PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  label:    PropTypes.string.isRequired,

  onChange:   PropTypes.func.isRequired,
  optionList: PropTypes.arrayOf(PropTypes.shape({
    value:    PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label:    PropTypes.string.isRequired,
    disabled: PropTypes.bool
  }))
};

SelectField.defaultProps = {
  value:     '',
  error:     null,
  className: null
};

export { SelectField };
