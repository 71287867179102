import PropTypes from 'prop-types';
import React     from 'react';

import * as S                  from './change-plan-bm.module.scss';
import { CurrentPlanBm }       from './current-plan-bm';
import { ModalWrapper }        from '../../modal-wrapper';
import { Spinner }             from '../../../common/spinner/spinner';
import { Message }             from '../../../common/message';
import { Price }               from '../../../common/product/price';
import { Quota }               from '../../../common/product/quota';
import * as Actions            from '../../../../context/ctx-actions';
import * as UserSelectors      from '../../../../context/selectors/user-selectors';
import * as ProductSelectors   from '../../../../context/selectors/product-selectors';
import { useGlobalCtx }        from '../../../../context/ctx-hook';
import * as BMSelectors        from '../../../../context/selectors/bill-selectors';
import * as Tealium            from '../../../../helpers/tealium';
import { BM_ACTIONS }          from '../../../../helpers/constants/constants';
import { MODALS }              from '../../../../helpers/constants/state-constants';
import { filterBMApiResponse } from '../../../../helpers/bmpromo-helpers';
import * as NetworkCalls       from '../../../../network/network-calls';

const setBPOrder = (planArr) =>
  planArr.sort((a, b) =>
    a.level === b.level
      ? 0
      : a.level < b.level ? -1 : 1
  ).map(({Tier}) => Tier);


const getBoxText = (plan, current, renewal, action, IsTrial, TermISODuration) => {
  if(IsTrial == 1) {
    let trailText = '';
    let duration = TermISODuration.replace(/\D/g, '');
    let term = '';

    if (TermISODuration.slice(-1).toLowerCase() == 'm') {
      if(duration <= 1) { term = 'Month'; }
      if(duration > 1) { term = 'Months'; }
    } else if (TermISODuration.slice(-1).toLowerCase() == 'y') {
      if(duration <= 1) { term = 'Year'; }
      if(duration > 1) { term = 'Years'; }
    }
    trailText = `Try ${duration}-${term} Free`;
    return trailText;
  }

  if (!current && !renewal) return 'Buy';
  if (['extra', 'standard'].includes(renewal)) return 'Buy';
  else if (plan === current) return 'Current Plan';
  else if (plan === renewal) return 'Renewal Plan';
  else if (action === BM_ACTIONS.UPGRADE) return 'Upgrade';
  else return 'Downgrade';
};

const getAction = (order, plan, current, IS_PROMO_ENT) => {
  if (IS_PROMO_ENT && !current ) {
    return BM_ACTIONS.CREATE;
  } else if (current == 'standard') {
    return BM_ACTIONS.CREATE;
  } else {
    return (order.indexOf(plan) > order.indexOf(current) ? BM_ACTIONS.UPGRADE : BM_ACTIONS.DOWNGRADE);
  }
};

const titleCase = (str) => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

const PRODUCT = 'billmanager';

const ChangePlanBm = ({onClose}) => {
  const {globalDispatch, state} = useGlobalCtx();

  // const HAS_ACH_PAYMENT     = getUserHasAch(state);
  const PRODUCT_PAYMENT     = UserSelectors.getProductsPaymentMethod(PRODUCT , state);
  const HAS_ACH_PAYMENT     = PRODUCT_PAYMENT ? PRODUCT_PAYMENT?.paymentMethodType === 'ACH' : false;
  const {current, renewal}  = BMSelectors.getBMPlan(state);
  const {order = [], plans} = state.billManager;

  const handleSelection = async (plan, sku, action) => {
    const bmSku = sku;

    try {
      globalDispatch(Actions.setCtxField('isDisabled', true));

      
      if (HAS_ACH_PAYMENT) {
        const {data} = await NetworkCalls.callPostChangeTier(plan, sku, action);
        if ([BM_ACTIONS.CREATE, BM_ACTIONS.UPGRADE].includes(action)) {
          // Tealium.tealiumLink(Tealium.BMTealiumEvent(state, data));
          Tealium.gtmLink(Tealium.BMTealiumEvent(state, data));
        }

        globalDispatch(Actions.mergeSubscriptionData(PRODUCT, data.postState));

        onClose();
      } else {
        globalDispatch(Actions.mergeMainStateObj({
          isDisabled: false,
          modal:      {
            type:       MODALS.UPDATE_PAYMENT,
            ctx: { product: PRODUCT, plan, bmSku, paymentOnly: false},
            isLoading:  true,
            hasError:   false,
            isDisabled: false
          }
        }));
      }
    } catch (err) {
      console.log(err);
      globalDispatch(Actions.mergeMainStateObj({
        isDisabled: false,
        modal:      {isLoading: false, hasError: true, isDisabled: false}
      }));
    }
  };

  const initChangeTierPage = React.useCallback(async () => {
    if (order.length > 0) return globalDispatch(Actions.mergeMainStateObj({modal: {isLoading: false}}));

    try {
      globalDispatch(Actions.mergeMainStateObj({modal: {isLoading: true}}));
      //const data = await NetworkCalls.callGetSkuInfo();
      //const transformedData = await NetworkCalls.transformSkuInfo(data);
      // Filtered skus are switched to operate off of pre-existing filtered list of SKUS
      const filteredSkus = filterBMApiResponse(state.skus);
      globalDispatch(Actions.mergeMainStateObj({
        modal:       {isLoading: false},
        billManager: {
          plans: filteredSkus.reduce((acm, cur) => ({...acm, [cur.Tier]: cur}), {}),
          order: setBPOrder(filteredSkus)
        }
      }));
    } catch (err) {
      console.log(err);
      globalDispatch(Actions.mergeMainStateObj({
        modal: {
          isLoading:  false,
          hasError:   true,
          isDisabled: false
        }
      }));
    }
  }, [order]);

  React.useEffect(() => {
    initChangeTierPage();
  }, [initChangeTierPage]);

  if (state.modal.isLoading) {
    return (
      <ModalWrapper heading="Change Plan" onClose={onClose}>
        <Spinner className={S.spinner}/>
      </ModalWrapper>
    );
  } else if (state.modal.hasError) {
    return (
      <ModalWrapper heading="Change Plan" onClose={onClose}>
        <Message
          type="ERROR"
          align="CENTER"
          messages={['Server error please try again later']}
        />
      </ModalWrapper>
    );
  } else {
    const CURRENT_INDEX  = order.indexOf(renewal ?? current ?? null);
    const CURRENT_ACTION = order.indexOf(current) < CURRENT_INDEX ? BM_ACTIONS.DOWNGRADE : BM_ACTIONS.UPGRADE;
    const IS_PROMO_ENT   = BMSelectors.getBMHasPromoEntitlement(state);
    const BM_TYPE        = BMSelectors.getBillManagerType(state);
    const BM_ENT = IS_PROMO_ENT ?
      ProductSelectors.getSubscription(state, 'billmanager-promotion') :
      BMSelectors.getMaxEntitlement(state);

    const BM_MAX_ENT = BMSelectors.getMaxEntitlement(state);
    const BM_FREE_DATA = BMSelectors.getBmTierData(BM_MAX_ENT);
    const { tierName } = BM_ENT;

    return (
      <ModalWrapper heading="Change Plan" onClose={onClose} className={S.mainWrapper}>
        <div className={S.modalBody}>
          {BM_TYPE === 'PAID' && (
            <CurrentPlanBm
              name={plans[current].CartProductName}
              sku={plans[current].SKU}
              quota={state.skus[plans[current].SKU].Quota}
              uriName={current}
              ctaText={getBoxText(current, current, renewal, CURRENT_ACTION)}
              handleChangePlan={() => handleSelection(current, plans[current].sku, order.indexOf(current) > CURRENT_INDEX ? 'UPGRADE' : 'DOWNGRADE')}
            />
          )}

          {BM_TYPE === 'FREE' && (
            <CurrentPlanBm
              // name={BM_FREE_TITLE}
              name={IS_PROMO_ENT ? BM_FREE_DATA.promoTierTitle : BM_FREE_DATA.tierTitle}
              sku={null}
              // quota={`checkpay=${IS_STANDARD ? 5 : 9}|billpay=12`}
              quota={`checkpay=${BM_FREE_DATA.checkPay}|billpay=${BM_FREE_DATA.quickPay}`}
              uriName={IS_PROMO_ENT ? tierName : current}
              ctaText={getBoxText(current, current, renewal, CURRENT_ACTION)}
              handleChangePlan={() => handleSelection(current, BM_ACTIONS.CREATE)}
              isPromo={IS_PROMO_ENT}
            />
          )}


          <div className={S.label}>
            {BM_TYPE === 'NONE' ? 'Bill Manager Plans' : 'Other Options'}
          </div>

          <div className={S.optionArea}>
            {order.map((plan, i) => {
              if (plan === current) return null;
              let { SKU, 
                Tier, 
                IsTrial, 
                RenewalPrice,
                TermISODuration,
                CartProductName } = plans[plan];
              const name = (IsTrial != 1) ? titleCase(CartProductName) : titleCase(CartProductName += ' Trial');
              const ACTION = getAction(order, plan, current, IS_PROMO_ENT);

              return (
                <article key={i} className={`${S.option} ${Tier}`} data-testid={`PLAN_${Tier.toUpperCase()}`}>
                  {name === 'Plus' &&
                    <div className={`${S.name}, ${S.mostPopContainer}`}>{name}
                      <span className={S.mostPopularTag}> Most Popular</span>
                    </div>
                  }
                  {name !== 'Plus' &&
                    <div className={S.name}>{name}
                    </div>
                  }
                  
                  <Quota sku={SKU} className={S.quota}/>
                  <Price
                    price={IsTrial != 1 ? undefined : RenewalPrice }
                    renewalFrequency="P1M"
                    className={S.optionPrice}
                    sku={SKU}
                  />

                  <button
                    className={S.contentCTABtn}
                    disabled={plan === renewal}
                    onClick={() => handleSelection(plan, SKU, ACTION)}
                  >{getBoxText(plan, current, renewal, ACTION, IsTrial, TermISODuration)}</button>
                </article>
              );
            })}
          </div>
        </div>
      </ModalWrapper>
    );
  }
};

ChangePlanBm.displayName = 'ChangePlanBm';
ChangePlanBm.propTypes   = {
  onClose: PropTypes.func.isRequired
};

export { ChangePlanBm };
