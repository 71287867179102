import React      from 'react';
import PropTypes  from 'prop-types';
import ClassNames from 'classnames';

import * as S     from './new-modal.module.scss';
import { XIcon }  from '../common/assets/x-icon';
// import QKN_RED    from '../../assets/imgs/brand/qkn-red.svg';
// import SIMP_LOGO  from '../../assets/imgs/brand/simp-icon.svg';
import QKN_SQUARE  from '../../assets/imgs/brand/logo-quicken-square.svg';

const ModalWrapper = ({ onClose, heading, className, headerClass, children, hasLogos, hideClose}) => {
  return (
    <section className={ClassNames(S.modal, className)}>
      <header className={ClassNames(S.modalHeader, headerClass)}>
        {hasLogos && (
          <div className={S.logoArea}>
            <img src={QKN_SQUARE} alt="Quicken logo"/>
          </div>
        )}
        <h2 className={ClassNames(S.headingText, {[S.isCentered]: hideClose})}>{heading}</h2>
        {!hideClose && (
          <button
            onClick={onClose}
            className={S.closeBtnIcon}
            data-testid="BTN_CLOSE_MODAL"
          ><XIcon color="#333" /></button>
        )}
      </header>
      {children}
    </section>
  );
};

ModalWrapper.displayName = 'ModalWrapper';
ModalWrapper.propTypes   = {
  hasLogos:  PropTypes.bool,
  hideClose: PropTypes.bool,
  onClose:   PropTypes.func.isRequired,
  heading:   PropTypes.string.isRequired,
  className: PropTypes.string,
  headerClass: PropTypes.string,
  children:  PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.array])
};

ModalWrapper.defaultProps = {
  hasLogos:  false,
  hideClose: false,
  className: null,
};

export { ModalWrapper };
