export const MFA_OPTIONS = [
  {value: 'EMAIL', label: 'Email'},
  {value: 'SMS',   label: 'Text Message'},
  {value: 'VOICE', label: 'Phone Call'}
];

export const MFA_DISABLED = [
  {value: 'EMAIL', label: 'Email', disabled: false},
  {value: 'SMS',   label: 'Text Message - (PHONE NUMBER REQUIRED)', disabled: true},
  {value: 'VOICE', label: 'Phone Call - (PHONE NUMBER REQUIRED)', disabled: true}
];

export const COUNTRY_OPTIONS = [
  {value: 'US', label: 'United States'},
  {value: 'CA', label: 'Canada'}
];

export const ACH_TYPE = [
  {value: 'CHECKING', label: 'Checking'},
  {value: 'SAVINGS',  label: 'Savings'}
];

export const ACH_OWNER_TYPE = [
  {value: 'personal', label: 'Personal'},
  {value: 'business', label: 'Business'}
];

export const CANADIAN_OPTIONS = [
  {value: '',   label: 'Make a selection'},
  {value: 'AB', label: 'Alberta'},
  {value: 'BC', label: 'British Columbia'},
  {value: 'MB', label: 'Manitoba'},
  {value: 'NB', label: 'New Brunswick'},
  {value: 'NF', label: 'Newfoundland'},
  {value: 'NS', label: 'Nova Scotia'},
  {value: 'NU', label: 'Nunavut'},
  {value: 'ON', label: 'Ontario'},
  {value: 'PE', label: 'Prince Edward Island'},
  {value: 'QU', label: 'Quebec'},
  {value: 'SK', label: 'Saskatchewan'},
  {value: 'YU', label: 'Yukon'}
];

export const US_STATES_TERRITORY = [
  {value: '',   label: 'Make a selection'},
  {value: 'AL', label: 'Alabama'},
  {value: 'AK', label: 'Alaska'},
  {value: 'AZ', label: 'Arizona'},
  {value: 'AR', label: 'Arkansas'},
  {value: 'AS', label: 'American Samoa'},
  {value: 'CA', label: 'California'},
  {value: 'CO', label: 'Colorado'},
  {value: 'CT', label: 'Connecticut'},
  {value: 'DC', label: 'District of Columbia'},
  {value: 'DE', label: 'Delaware'},
  {value: 'FL', label: 'Florida'},
  {value: 'GA', label: 'Georgia'},
  {value: 'GU', label: 'Guam'},
  {value: 'HI', label: 'Hawaii'},
  {value: 'IA', label: 'Iowa'},
  {value: 'ID', label: 'Idaho'},
  {value: 'IN', label: 'Indiana'},
  {value: 'IL', label: 'Illinois'},
  {value: 'KS', label: 'Kansas'},
  {value: 'KY', label: 'Kentucky'},
  {value: 'LA', label: 'Louisiana'},
  {value: 'MA', label: 'Massachusetts'},
  {value: 'MD', label: 'Maryland'},
  {value: 'ME', label: 'Maine'},
  {value: 'MI', label: 'Michigan'},
  {value: 'MN', label: 'Minnesota'},
  {value: 'MO', label: 'Missouri'},
  {value: 'MP', label: 'Northern Marina Islands'},
  {value: 'MS', label: 'Mississippi'},
  {value: 'MT', label: 'Montana'},
  {value: 'NC', label: 'North Carolina'},
  {value: 'ND', label: 'North Dakota'},
  {value: 'NE', label: 'Nebraska'},
  {value: 'NH', label: 'New Hamspire'},
  {value: 'NJ', label: 'New Jersey'},
  {value: 'NM', label: 'New Mexico'},
  {value: 'NV', label: 'Nevada'},
  {value: 'NY', label: 'New York'},
  {value: 'OH', label: 'Ohio'},
  {value: 'OK', label: 'Oklahoma'},
  {value: 'OR', label: 'Oregon'},
  {value: 'PA', label: 'Pennsylvania'},
  {value: 'PR', label: 'Puerto Rico'},
  {value: 'RI', label: 'Rhode Island'},
  {value: 'SC', label: 'South Carolina'},
  {value: 'SD', label: 'South Dakota'},
  {value: 'TN', label: 'Tennessee'},
  {value: 'TX', label: 'Texas'},
  {value: 'UM', label: 'United States Minor Outlying Islands'},
  {value: 'UT', label: 'Utah'},
  {value: 'VA', label: 'Virginia'},
  {value: 'VI', label: 'Virgin Islands'},
  {value: 'VT', label: 'Vermont'},
  {value: 'WA', label: 'Washington'},
  {value: 'WI', label: 'Wisconsin'},
  {value: 'WV', label: 'West Virginia'},
  {value: 'WY', label: 'Wyoming'},
];
