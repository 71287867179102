import React            from 'react';
import PropTypes        from 'prop-types';
import ClassNames       from 'classnames';

import * as S             from './price.module.scss';
import { useGetSkuPrice } from '../../../helpers/hooks/use-get-price';
import { useGlobalCtx }   from '../../../context/ctx-hook';
import { getPriceBySku }  from '../../../context/selectors/product-selectors';
import { Discount } from './discount';


const Price = ({renewalFrequency, product, className, sku, price, discountObj}) => {
  const {state} = useGlobalCtx();
  const qknProducts = ['quicken-us', 'quicken-ca'];

  let IS_YEARLY = false;
  let IS_MONTHLY = false;

  let HAS_DISCOUNT =  discountObj && discountObj?.DisplayFullPrice != '';

  if (qknProducts.includes(product)) {
    IS_YEARLY = true;
    IS_MONTHLY = false;
  } else if (renewalFrequency === 'P1Y') {
    IS_YEARLY = true;
    IS_MONTHLY = false;
  } else if (renewalFrequency === 'P1M') {
    IS_YEARLY = false;
    IS_MONTHLY = true;
  } else if (renewalFrequency === 'P30D'){
    IS_YEARLY = true;
    IS_MONTHLY = false;
  }

  let intervalStr = '';
  if (IS_YEARLY) { intervalStr = 'yr'; }
  if (IS_MONTHLY) { intervalStr = 'mo'; }

  useGetSkuPrice(sku);
  const PRICE = getPriceBySku(state, sku);

  return (
    <div>
      {HAS_DISCOUNT && <Discount discountPrice={discountObj?.DisplayFullPrice} displayDiscount={discountObj?.DisplayDiscount}/>}
      <div className={ClassNames(S.priceArea, className)} data-testid="SUB_PRICE">
        {(PRICE || price) && <span className={S.price}>{price || PRICE}</span> }
        {(PRICE || price) && <span className={S.dash}>/</span> }
        {(PRICE || price) && <span className={S.interval}>{intervalStr}</span> }
      </div>
    </div>
  );
};

Price.displayName = 'Price';
Price.propTypes   = {
  price:            PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sku:              PropTypes.string,
  className:        PropTypes.string,
  product:          PropTypes.string,
  renewalFrequency: PropTypes.oneOf(['P1Y', 'P1M', 'P30D']).isRequired,
  discountObj:      PropTypes.oneOfType([PropTypes.object, PropTypes.boolean])
};

Price.defaultProps = {
  className: null
};

export { Price };
