import PropTypes from 'prop-types';
import React     from 'react';

import { SimpActive }            from './simp-active';
import { SimpCancelled }         from './simp-cancelled';
import { SimpMobile }            from './simp-mobile';
import { CancelledBox }          from '../../../common/product/cancelled-box';
import { ProductName }           from '../../../common/product/prod-name';
import { TagLine }               from '../../../common/product/tag-line';
import { useGlobalCtx }          from '../../../../context/ctx-hook';
import * as ProductSelectors     from '../../../../context/selectors/product-selectors';
import { getUserSubHistory }     from '../../../../context/selectors/user-selectors';
import { daysLeft, isInBetween } from '../../../../helpers/time-helpers';


import * as S from './simp-tile.module.scss';

const SimpTile = ({isModal, product}) => {
  const {state}      = useGlobalCtx();
  const SUB          = ProductSelectors.getSubscription(state, product);
  const HISTORY_LIST = getUserSubHistory(state, product);
  const IS_MOBILE    = product !== 'acme';

  const IS_CANCELLED = React.useMemo(
    () =>
      !SUB.active || (!SUB.autoRenew && HISTORY_LIST?.filter(({action, createdAt}) =>
        (action === 'DISABLE_AUTORENEW' && isInBetween(SUB.effectiveAt, SUB.expireOn, createdAt))
      ).length > 0),
    [SUB.autoRenew, SUB.effectiveAt, SUB.expireOn, HISTORY_LIST]
  );

  const DAYS_LEFT = React.useMemo(
    () => daysLeft(SUB.expireOn),
    [SUB.expireOn]
  );

  return (
    <div className={`${S.simpTile} acme`}>
      {IS_CANCELLED && <CancelledBox className={S.cancelTrialBox}/>}
      <ProductName name="Simplifi" className={S.acmeName}/>
      <TagLine uriName={SUB.tierUriName} className={S.acmeTagline}/>
      {IS_MOBILE
        ? <SimpMobile product={product} {...SUB} />
        : DAYS_LEFT > 0
          ? <SimpActive {...SUB} isCancelled={IS_CANCELLED} isModal={isModal}/>
          : <SimpCancelled {...SUB} />
      }
    </div>
  );
};

SimpTile.displayName  = 'SimpTile';
SimpTile.propTypes    = {
  isModal: PropTypes.bool,
  product: PropTypes.string.isRequired
};
SimpTile.defaultProps = {isModal: false};

export { SimpTile };
