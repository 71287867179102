import { SIMP_SKUS } from '../../helpers/constants/constants';

export const getRelatedSkus = (sku) => {
  if ([
    SIMP_SKUS.P_1M,
    SIMP_SKUS.P_1Y,
    SIMP_SKUS.P_1M_ALT,
    SIMP_SKUS.P_1Y_ALT
  ].includes(sku)) {
    return {
      monthlySku: SIMP_SKUS.P_1M_ALT,
      annualSku:  SIMP_SKUS.P_1Y_ALT
    };
  } else if ([
    SIMP_SKUS.F_1M_v2,
    SIMP_SKUS.F_1Y_v2,
    SIMP_SKUS.P_1M_v2,
    SIMP_SKUS.P_1Y_v2
  ].includes(sku)) {
    return {
      monthlySku: SIMP_SKUS.P_1M_v2,
      annualSku:  SIMP_SKUS.P_1Y_v2
    };
  } else if ([
    SIMP_SKUS.F_1M_v3,
    SIMP_SKUS.F_1Y_v3,
    SIMP_SKUS.P_1M_v3,
    SIMP_SKUS.P_1Y_v3
  ].includes(sku)) {
    return {
      monthlySku: SIMP_SKUS.P_1M_v3,
      annualSku:  SIMP_SKUS.P_1Y_v3
    };
  } else if ([
    SIMP_SKUS.F_1M_EDU_TEX,
    SIMP_SKUS.P_1M_EDU_TEX,
    SIMP_SKUS.P_1Y_EDU_TEX
  ].includes(sku)) {
    return {
      monthlySku: SIMP_SKUS.P_1M_EDU_TEX,
      annualSku:  SIMP_SKUS.P_1Y_EDU_TEX
    };
  } else if ([
    SIMP_SKUS.P_1Y_EDU_OLM,
    SIMP_SKUS.P_1M_EDU_OLM,
    SIMP_SKUS.F_1M_EDU_OLM_1M_P,
    SIMP_SKUS.F_1M_EDU_OLM_1Y_P
  ].includes(sku)) {
    return {
      monthlySku: SIMP_SKUS.P_1M_EDU_OLM,
      annualSku:  SIMP_SKUS.P_1Y_EDU_OLM
    };
  } else if ([
    SIMP_SKUS.P_1M_EDU_JMU,
    SIMP_SKUS.P_1Y_EDU_JMU,
    SIMP_SKUS.F_3M_EDU_JMU_1M_P,
    SIMP_SKUS.F_3M_EDU_JMU_1Y_P
  ].includes(sku)) {
    return {
      monthlySku: SIMP_SKUS.P_1M_EDU_JMU,
      annualSku:  SIMP_SKUS.P_1Y_EDU_JMU
    };
  } else if ([
    SIMP_SKUS.F_1M_EDU_MI,
    SIMP_SKUS.F_1Y_EDU_MI,
    SIMP_SKUS.P_1M_EDU_MI,
    SIMP_SKUS.P_1Y_EDU_MI,
  ].includes(sku)) {
    return {
      monthlySku: SIMP_SKUS.P_1M_EDU_MI,
      annualSku:  SIMP_SKUS.P_1Y_EDU_MI
    };
  } else if ([
    SIMP_SKUS.F_3M_EDU_MI_1M_P,
    SIMP_SKUS.F_3M_EDU_MI_1Y_P,
    SIMP_SKUS.P_1Y_EDU_MI_1Y_P,
  ].includes(sku)) {
    return {
      monthlySku: SIMP_SKUS.P_1M_EDU_MI,
      annualSku:  SIMP_SKUS.P_1Y_EDU_MI_1Y_P
    };
  } else if ([
    SIMP_SKUS.F_1M_EDU_BUF_1M_P,
    SIMP_SKUS.F_1M_EDU_BUF_1Y_P,
    SIMP_SKUS.P_1M_EDU_BUF,
    SIMP_SKUS.P_1Y_EDU_BUF
  ].includes(sku)) {
    return {
      monthlySku: SIMP_SKUS.P_1M_EDU_BUF,
      annualSku:  SIMP_SKUS.P_1Y_EDU_BUF
    };
  } else if ([
    SIMP_SKUS.F_1M_EDU_ORG_1M_P,
    SIMP_SKUS.F_1M_EDU_ORG_1Y_P,
    SIMP_SKUS.P_1M_EDU_ORG,
    SIMP_SKUS.P_1Y_EDU_ORG
  ].includes(sku)) {
    return {
      monthlySku: SIMP_SKUS.P_1M_EDU_ORG,
      annualSku:  SIMP_SKUS.P_1Y_EDU_ORG
    };
  } else {
    return {
      monthlySku: null,
      annualSku:  null
    };
  }
};
