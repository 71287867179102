import React     from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import * as S from './prod-name.module.scss';


const ProductName = ({name, className, inline}) => {
  if (!name) { 
    return (<div className={ClassNames(S.prodName, className)}></div> );
  }
  
  const isSimplifi = name?.toLowerCase().includes('simplifi');
  const isQem = name?.toLowerCase().includes('estate manager') || name?.toLowerCase().includes('lifehub');
  const isQuicken = ['starter', 'deluxe', 'premier', 'business & personal'].includes(name?.toLowerCase());
  const isQob = name?.toLowerCase().includes('qob') || name?.toLowerCase().includes('online backup');

  const nameContent = () => {
    if (isSimplifi || isQem) {
      // Strip off any existing product name that comes in starting with Quicken because we are going to prepend it in our span for theme styling.
      if(name.startsWith('Quicken ')) {
        name = name.split('Quicken ')[1];
      }
      return (<><span className={S.simpTheme}>Quicken</span> {name}</>);
    } else if(isQuicken) {
      return (<><span className={S.qknTheme}>Quicken</span> Classic {name}</>);
    } else if(isQob){
      return (<><span className={S.qknTheme}>Quicken</span> Online Backup</>);
    }else {
      return(name);
    }
  };

  if(inline){
    return (<span className={ClassNames(S.prodName, className)}> {nameContent()} </span>);
  }else{
    return (<div className={ClassNames(S.prodName, className)}> {nameContent()} </div>);
  }
};

ProductName.displayName = 'CompanyBox';
ProductName.propTypes   = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  inline : PropTypes.bool
};
ProductName.defaultProps ={
  inline : false
};

export { ProductName };
